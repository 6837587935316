import React, { useState, useCallback } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const EditMarkersModal = ({ show, onClose, markers, onDelete }) => {
    const [selectedMarkers, setSelectedMarkers] = useState([]);

    const handleMarkerChange = useCallback((event) => {
        const markerId = parseInt(event.target.value, 10);
        setSelectedMarkers(prevState =>
            prevState.includes(markerId)
                ? prevState.filter(id => id !== markerId)
                : [...prevState, markerId]
        );
    }, []);

    const handleDelete = useCallback(() => {
        console.log('Selected markers to delete:', selectedMarkers);
        onDelete(selectedMarkers);
        setSelectedMarkers([]);
    }, [selectedMarkers, onDelete]);

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Delete Markers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {markers.map(marker => (
                        <Form.Check 
                            type="checkbox"
                            key={marker.id}
                            value={marker.id}
                            label={marker.geojson.properties.name}
                            checked={selectedMarkers.includes(marker.id)}
                            onChange={handleMarkerChange}
                        />
                    ))}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Close</Button>
                <Button variant="danger" onClick={handleDelete}>Delete Selected</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditMarkersModal;
