import React, { useEffect, useRef, useState } from 'react';
import { useMap } from 'react-leaflet';
import { icons } from './utils';
import L from 'leaflet';

const determineIconType = (displayName) => {
    if (displayName.includes('K9')) {
        return 'k9';
    } else if (displayName.includes('Marine')) {
        return 'boat';
    } else if (displayName.includes('Aviation')) {
        return 'helicopter';
    } else if (displayName.includes('ESU')) {
        return 'esu';
    } else {
        return 'policeCar';
    }
};

const calculateTooltipOffset = (index) => {
    const firstTooltipOffset = -10; // The first tooltip is 10 pixels above the marker
    const additionalOffset = -40 * index; // Each subsequent tooltip is 40 pixels above the previous one
    const verticalOffset = firstTooltipOffset + additionalOffset;
    return [0, verticalOffset];
};

const VehicleLayer = ({ setVehicles }) => {
    const markersRef = useRef({});
    const map = useMap();
    const [vehicles, setVehicleState] = useState([]);
    const lastEventTimeRef = useRef(Date.now());
    const zoomLevelRef = useRef(map.getZoom()); // Keep track of the last zoom level

    useEffect(() => {
        const checkHealth = () => {
            const now = Date.now();
            if (now - lastEventTimeRef.current > 30000) { // 30 seconds threshold
                console.warn("No SSE events received for 30 seconds, refreshing the page.");
                window.location.reload(); // Refresh the page
            }
        };

        const healthCheckInterval = setInterval(checkHealth, 10000); // Check every 10 seconds

        const source = new EventSource('/sse/vehicles');
        source.onmessage = (event) => {
            try {
                lastEventTimeRef.current = Date.now(); // Update the last event time
                const data = JSON.parse(event.data);
                if (data.type === 'FeatureCollection' && Array.isArray(data.features)) {
                    const newVehicles = data.features.map(feature => ({
                        ...feature.properties,
                        latitude: feature.geometry.coordinates[1],
                        longitude: feature.geometry.coordinates[0],
                        iconType: determineIconType(feature.properties.displayName),
                    }));
                    setVehicleState(newVehicles);
                    setVehicles(newVehicles);
                }
            } catch (error) {
                console.error("Error parsing received data:", error);
            }
        };

        source.onerror = (error) => {
            console.error("SSE error:", error);
            source.close();
        };

        return () => {
            clearInterval(healthCheckInterval);
            source.close();
        };
    }, [setVehicles]);

    useEffect(() => {
        const adjustTooltipsInViewport = () => {
            const mapBounds = map.getBounds(); // Get the current viewport bounds

            const markersInViewport = Object.keys(markersRef.current)
                .filter(displayName => {
                    const marker = markersRef.current[displayName];
                    return mapBounds.contains(marker.getLatLng());
                })
                .map((displayName, index) => {
                    const marker = markersRef.current[displayName];
                    const tooltipOffset = calculateTooltipOffset(index);

                    console.log(`Marker "${displayName}" is in the viewport. Applying offset:`, tooltipOffset);

                    marker.getTooltip().options.offset = tooltipOffset; // Update the tooltip offset
                    marker.getTooltip().update(); // Force update to apply the offset

                    return marker;
                });

            console.log(`Adjusted ${markersInViewport.length} tooltips in the viewport.`);
        };

        const resetTooltips = () => {
            Object.keys(markersRef.current).forEach(displayName => {
                const marker = markersRef.current[displayName];
                const defaultOffset = [0, 0]; // Default offset for tooltips

                console.log(`Resetting offset for marker "${displayName}" to default:`, defaultOffset);

                marker.getTooltip().options.offset = defaultOffset; // Reset the tooltip offset
                marker.getTooltip().update(); // Force update to apply the reset
            });
        };

        const currentZoom = map.getZoom();

        if (currentZoom >= 18 && zoomLevelRef.current < 18) {
            // Zoomed in to 18 or more, adjust tooltips
            adjustTooltipsInViewport();
        } else if (currentZoom < 18 && zoomLevelRef.current >= 18) {
            // Zoomed out below 18, reset tooltips
            resetTooltips();
        }

        zoomLevelRef.current = currentZoom; // Update last zoom level

        vehicles.forEach((vehicle) => {
            const { displayName, latitude, longitude, heading, iconType } = vehicle;
            const latlng = [latitude, longitude];

            let icon;
            if (typeof icons[iconType] === 'function') {
                icon = icons[iconType](heading);
            } else {
                console.error(`Icon type "${iconType}" is not valid. Falling back to policeCarIcons for vehicle "${displayName}".`);
                icon = icons.policeCar(heading);
            }

            let marker = markersRef.current[displayName];
            if (marker) {
                marker.setLatLng(latlng).setIcon(icon);
            } else {
                marker = L.marker(latlng, { icon }).addTo(map);
                marker.bindTooltip(displayName, { permanent: true, direction: "top", className: 'custom-tooltip' });
                markersRef.current[displayName] = marker;
            }
        });

        Object.keys(markersRef.current).forEach(displayName => {
            if (!vehicles.some(v => v.displayName === displayName)) {
                const marker = markersRef.current[displayName];
                if (marker) {
                    map.removeLayer(marker);
                    delete markersRef.current[displayName];
                }
            }
        });
    }, [vehicles, map]);

    useEffect(() => {
        return () => {
            Object.keys(markersRef.current).forEach(displayName => {
                const marker = markersRef.current[displayName];
                if (marker) {
                    map.removeLayer(marker);
                    delete markersRef.current[displayName];
                }
            });
        };
    }, [map]);

    return null;
};

export default VehicleLayer;
