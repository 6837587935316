import L from 'leaflet';

// Helper to create an icon object
const createIcon = (url) => L.icon({
    iconUrl: url,
    iconSize: [38, 38],  // Size of the icon
    iconAnchor: [19, 19],  // Anchor point of the icon to handle rotation correctly if applicable
    popupAnchor: [0, -19]  // Where the popups should be relative to the icon
});

// Police Car Icons
export const policeCarIcons = {
    icon0: createIcon('/images/icons/policecar/policeCar0.png'),
    icon45: createIcon('/images/icons/policecar/policeCar45.png'),
    icon90: createIcon('/images/icons/policecar/policeCar90.png'),
    icon135: createIcon('/images/icons/policecar/policeCar135.png'),
    icon180: createIcon('/images/icons/policecar/policeCar180.png'),
    icon225: createIcon('/images/icons/policecar/policeCar225.png'),
    icon270: createIcon('/images/icons/policecar/policeCar270.png'),
    icon315: createIcon('/images/icons/policecar/policeCar315.png'),
};

// K9 Unit Icons
export const k9Icons = {
    icon0: createIcon('/images/icons/k9/k90.png'),
    icon45: createIcon('/images/icons/k9/k945.png'),
    icon90: createIcon('/images/icons/k9/k990.png'),
    icon135: createIcon('/images/icons/k9/k9135.png'),
    icon180: createIcon('/images/icons/k9/k9180.png'),
    icon225: createIcon('/images/icons/k9/k9225.png'),
    icon270: createIcon('/images/icons/k9/k9270.png'),
    icon315: createIcon('/images/icons/k9/k9315.png'),
};

// Helicopter Icons
export const helicopterIcons = {
    icon0: createIcon('/images/icons/helicopter/helicopter0.png'),
    icon45: createIcon('/images/icons/helicopter/helicopter45.png'),
    icon90: createIcon('/images/icons/helicopter/helicopter90.png'),
    icon135: createIcon('/images/icons/helicopter/helicopter135.png'),
    icon180: createIcon('/images/icons/helicopter/helicopter180.png'),
    icon225: createIcon('/images/icons/helicopter/helicopter225.png'),
    icon270: createIcon('/images/icons/helicopter/helicopter270.png'),
    icon315: createIcon('/images/icons/helicopter/helicopter315.png'),
};

// ESU Icons
export const esuIcons = {
    icon0: createIcon('/images/icons/esu/esu0.png'),
    icon45: createIcon('/images/icons/esu/esu45.png'),
    icon90: createIcon('/images/icons/esu/esu90.png'),
    icon135: createIcon('/images/icons/esu/esu135.png'),
    icon180: createIcon('/images/icons/esu/esu180.png'),
    icon225: createIcon('/images/icons/esu/esu225.png'),
    icon270: createIcon('/images/icons/esu/esu270.png'),
    icon315: createIcon('/images/icons/esu/esu315.png'),
};

// Boat Icons
export const boatIcons = {
    icon0: createIcon('/images/icons/marine/boat0.png'),
    icon45: createIcon('/images/icons/marine/boat45.png'),
    icon90: createIcon('/images/icons/marine/boat90.png'),
    icon135: createIcon('/images/icons/marine/boat135.png'),
    icon180: createIcon('/images/icons/marine/boat180.png'),
    icon225: createIcon('/images/icons/marine/boat225.png'),
    icon270: createIcon('/images/icons/marine/boat270.png'),
    icon315: createIcon('/images/icons/marine/boat315.png'),
};

const calculateIcon = (heading) => {
    const roundedHeading = Math.round(heading / 45) * 45 % 360;
    return `icon${roundedHeading}`;
};

export const icons = {
    policeCar: (heading) => policeCarIcons[calculateIcon(heading)],
    k9: (heading) => k9Icons[calculateIcon(heading)],
    helicopter: (heading) => helicopterIcons[calculateIcon(heading)],
    esu: (heading) => esuIcons[calculateIcon(heading)],
    boat: (heading) => boatIcons[calculateIcon(heading)],
};

export const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
};
