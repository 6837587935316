import React, { useCallback } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const LayerControlModal = ({ show, handleClose, csvFiles, selectedLayers, setSelectedLayers, selectedMapType, onMapTypeChange }) => {
  const handleCheckboxChange = useCallback((filename) => {
    setSelectedLayers(prevLayers => 
      prevLayers.includes(filename) ? prevLayers.filter(layer => layer !== filename) : [...prevLayers, filename]
    );
  }, [setSelectedLayers]);

  const handleRadioChange = useCallback((e) => {
    onMapTypeChange(e.target.value);
  }, [onMapTypeChange]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Select Layers & Map Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div style={{ flex: 1 }}>
              <Form.Group>
                <Form.Label as="legend">Choose Map Type</Form.Label>
                <Form.Check 
                  type="radio" 
                  label="OpenStreetMap" 
                  value="openStreetMap" 
                  checked={selectedMapType === 'openStreetMap'} 
                  onChange={handleRadioChange}
                />
                <Form.Check 
                  type="radio" 
                  label="Google Maps" 
                  value="googleMaps" 
                  checked={selectedMapType === 'googleMaps'} 
                  onChange={handleRadioChange}
                />
                <Form.Check 
                  type="radio" 
                  label="Google Satellite" 
                  value="googleSatellite" 
                  checked={selectedMapType === 'googleSatellite'} 
                  onChange={handleRadioChange}
                />
              </Form.Group>
            </div>
            <div style={{ width: '1px', backgroundColor: '#ccc', margin: '0 15px' }} />
            <div style={{ flex: 1 }}>
              <Form.Group>
                <Form.Label as="legend">User Added Layers</Form.Label>
                {csvFiles.map((file, index) => (
                  <Form.Group key={index} controlId={`formCheckbox-${index}`}>
                    <Form.Check 
                      type="checkbox" 
                      label={file}
                      checked={selectedLayers.includes(file)}
                      onChange={() => handleCheckboxChange(file)}
                    />
                  </Form.Group>
                ))}
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LayerControlModal;
