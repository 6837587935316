import React, { useState, useEffect } from 'react';
import Fuse from 'fuse.js';
import '../App.css';

const VehicleSearch = ({ vehicles, onVehicleSelect }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  useEffect(() => {
    //console.log('Vehicles data:', vehicles); // Log the vehicles data
    if (query) {
      const fuse = new Fuse(vehicles, {
        keys: ['displayName'],
        includeScore: true,
      });
      const searchResults = fuse.search(query).map(result => result.item);
      //console.log('Search results:', searchResults); // Log the search results
      setResults(searchResults);
    } else {
      setResults([]);
    }
  }, [query, vehicles]);

  const handleSelect = (vehicle) => {
    onVehicleSelect(vehicle);
    setQuery('');
    setResults([]);
  };

  return (
    <div className="vehicle-search-container">
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Search vehicles..."
        className="vehicle-search-input"
      />
      {results.length > 0 && (
        <ul className="vehicle-search-results">
          {results.map(vehicle => (
            <li key={vehicle.displayName} onClick={() => handleSelect(vehicle)}>
              {vehicle.displayName}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default VehicleSearch;
