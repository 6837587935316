import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';

const EditIncModal = ({ incidentId, onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        type_incident: '',
        incident_commander: '',
        staging_manager: '',
        communications: '',
    });

    useEffect(() => {
        // This effect fetches incident data for editing
        const source = axios.CancelToken.source();  // Creating a cancel token for cleanup

        const fetchIncidentData = async () => {
            try {
                const response = await axios.get(`https://merlin.westchesterrtc.com/api/incidents/${incidentId}`, {
                    cancelToken: source.token  // Using the cancel token in the request
                });
                setFormData(response.data);
                console.log('Fetched incident data:', response.data);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error('Error fetching incident data:', error);
                }
            }
        };

        fetchIncidentData();

        // Cleanup function to cancel the request if the component unmounts
        return () => {
            source.cancel("Component unmounted and request cancelled");
        };
    }, [incidentId]);

    const handleChange = (e) => {
        setFormData(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`https://merlin.westchesterrtc.com/api/incidents/${incidentId}`, formData);
            console.log('Incident updated successfully:', response.data);
            onClose();
        } catch (error) {
            console.error('Error updating incident:', error);
        }
    };

    return (
        <Modal show={true} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Incident</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formTypeIncident" className="mt-3">
                        <Form.Label>Incident Type</Form.Label>
                        <Form.Control
                            type="text"
                            name="type_incident"
                            value={formData.type_incident}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formIncidentCommander" className="mt-3">
                        <Form.Label>Incident Commander</Form.Label>
                        <Form.Control
                            type="text"
                            name="incident_commander"
                            value={formData.incident_commander}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formStagingManager" className="mt-3">
                        <Form.Label>Staging Manager</Form.Label>
                        <Form.Control
                            type="text"
                            name="staging_manager"
                            value={formData.staging_manager}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formCommunications" className="mt-3">
                        <Form.Label>Communications</Form.Label>
                        <Form.Control
                            type="text"
                            name="communications"
                            value={formData.communications}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="mt-3">
                        Update
                    </Button>
                    <Button variant="secondary" onClick={onClose} className="mt-3">
                        Cancel
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditIncModal;
