import React, { useEffect, useState, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import MapPage from './components/MapPage';
import LoginPage from './components/Login';
import AdminPage from './components/AdminPage';
import VehicleRoster from './components/VehicleRoster'; // Import the VehicleRoster component

function useQuery() {
    const location = useLocation();
    return useMemo(() => new URLSearchParams(location.search), [location]);
}

function ProtectedRoute({ isAuthenticated, children }) {
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated, navigate]);

    return isAuthenticated ? children : null;
}

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userData, setUserData] = useState(null);
    const [selectedIncident, setSelectedIncident] = useState(null); // Manage selected incident
    const navigate = useNavigate();
    const query = useQuery();

    useEffect(() => {
        const userDataFromQuery = query.get('user');
        if (userDataFromQuery) {
            const parsedUserData = JSON.parse(decodeURIComponent(userDataFromQuery));
            const storedUser = localStorage.getItem('user');
            if (storedUser !== JSON.stringify(parsedUserData)) {
                localStorage.setItem('user', JSON.stringify(parsedUserData));
            }
            setUserData(parsedUserData);
            setIsAuthenticated(true);
        } else {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                const parsedUser = JSON.parse(storedUser);
                setUserData(parsedUser);
                setIsAuthenticated(true);
            } else {
                navigate('/login');
            }
        }
    }, [query, navigate]);

    if (!userData && !isAuthenticated) {
        return null; // Or a loading indicator
    }

    return (
        <Routes>
            <Route
                path="/map"
                element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <MapPage setSelectedIncident={setSelectedIncident} /> {/* Pass setter for selected incident */}
                    </ProtectedRoute>
                }
            />
            <Route
                path="/admin"
                element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <AdminPage />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/roster"
                element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <VehicleRoster selectedIncident={selectedIncident} /> {/* Pass the selected incident */}
                    </ProtectedRoute>
                }
            />
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<LoginPage />} />
        </Routes>
    );
}

function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}

export default AppWrapper;
