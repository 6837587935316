import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const CloseIncidentModal = ({ show, onClose, onSubmit, incidentId }) => {
    const [dispositions, setDispositions] = useState([]);
    const [selectedDisposition, setSelectedDisposition] = useState('');
    const [notes, setNotes] = useState('');

    useEffect(() => {
        // Fetch dispositions from the database
        fetch('https://merlin.westchesterrtc.com/api/dispositions')
            .then(response => response.json())
            .then(data => setDispositions(data))
            .catch(error => console.error('Error fetching dispositions:', error));
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();

        // Extract user information from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const user = JSON.parse(urlParams.get('user'));

        const closeData = {
            incident_id: incidentId,
            disposition: selectedDisposition,
            notes: notes,
            userName: user?.userName || 'Unknown',
            userId: user?.userId || 'Unknown'
        };
        onSubmit(closeData);
        onClose();
    };

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Close Incident</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formDisposition">
                        <Form.Label>Disposition</Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedDisposition}
                            onChange={(e) => setSelectedDisposition(e.target.value)}
                            required
                        >
                            <option value="">Select Disposition</option>
                            {dispositions.map((disposition) => (
                                <option key={disposition.id} value={disposition.disposition_text}>
                                    {disposition.disposition_text}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formNotes" className="mt-3">
                        <Form.Label>Notes</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formNotification" className="mt-3">
                        <Form.Text className="text-muted">
                            Report will be sent to the SLACK #map channel. If you do not have access, please contact the Ops desk and they will email it to you.
                        </Form.Text>
                    </Form.Group>
                    <Button variant="primary" type="submit" className="mt-3">
                        Submit
                    </Button>
                    <Button variant="secondary" onClick={onClose} className="mt-3 ms-2">
                        Close
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CloseIncidentModal;
