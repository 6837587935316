import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminPage = () => {
    const [email, setEmail] = useState('');
    const [csvFile, setCsvFile] = useState(null);
    const [logsMerlin, setLogsMerlin] = useState('');
    const [logsZello, setLogsZello] = useState('');
    const [authorizedEmails, setAuthorizedEmails] = useState([]);
    const [auditTrail, setAuditTrail] = useState([]);
    const [merlinStatus, setMerlinStatus] = useState('unknown');
    const [zelloStatus, setZelloStatus] = useState('unknown');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [password, setPassword] = useState('');

    const checkAuthentication = () => {
        if (password === 'adminPassword') { // Hardcoded password
            setIsAuthenticated(true);
        } else {
            alert('Incorrect password');
        }
    };

    const handleAddEmail = async () => {
        try {
            await axios.post('/api/admin/add-email', { email });
            alert('Email added successfully');
            setEmail(''); // Clear the input field
            fetchAuthorizedEmails(); // Refresh the email list
        } catch (error) {
            alert('Failed to add email');
        }
    };

    const handleUploadCSV = async () => {
        const formData = new FormData();
        formData.append('csvFile', csvFile);

        try {
            await axios.post('/api/admin/upload-csv', formData);
            alert('CSV uploaded successfully');
            setCsvFile(null); // Clear the file input
        } catch (error) {
            alert('Failed to upload CSV');
        }
    };

    const fetchLogsMerlin = async () => {
        try {
            const response = await axios.get('/api/admin/logs/merlin');
            setLogsMerlin(response.data);
        } catch (error) {
            alert('Failed to fetch Merlin logs');
        }
    };

    const fetchLogsZello = async () => {
        try {
            const response = await axios.get('/api/admin/logs/zello');
            setLogsZello(response.data);
        } catch (error) {
            alert('Failed to fetch Zello logs');
        }
    };

    const fetchAuthorizedEmails = async () => {
        try {
            const response = await axios.get('/api/admin/emails');
            setAuthorizedEmails(response.data);
        } catch (error) {
            alert('Failed to fetch authorized emails');
        }
    };

    const fetchAuditTrail = async () => {
        try {
            const response = await axios.get('/api/admin/audit-trail');
            setAuditTrail(response.data);
        } catch (error) {
            alert('Failed to fetch audit trail');
        }
    };

    const fetchServiceStatus = async () => {
        try {
            const merlinResponse = await axios.get('/api/admin/status/merlin');
            const zelloResponse = await axios.get('/api/admin/status/zello');
            setMerlinStatus(merlinResponse.data.status);
            setZelloStatus(zelloResponse.data.status);
        } catch (error) {
            alert('Failed to fetch service status');
        }
    };

    useEffect(() => {
        fetchLogsMerlin();
        fetchLogsZello();
        fetchAuthorizedEmails(); // Fetch the email list on load
        fetchAuditTrail(); // Fetch the audit trail on load
        fetchServiceStatus(); // Fetch service status on load
    }, []);

    if (!isAuthenticated) {
        return (
            <div style={styles.container}>
                <div style={styles.card}>
                    <h2 style={styles.heading}>Admin Login</h2>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter Admin Password"
                        style={styles.input}
                    />
                    <button onClick={checkAuthentication} style={styles.button}>Login</button>
                </div>
            </div>
        );
    }

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <h2 style={styles.heading}>Admin Dashboard</h2>
                
                <div style={styles.statusContainer}>
                    <div style={{ ...styles.statusLight, backgroundColor: merlinStatus === 'active' ? 'green' : 'red' }}></div>
                    <p style={styles.statusText}>Merlin Service: {merlinStatus}</p>
                </div>
                
                <div style={styles.statusContainer}>
                    <div style={{ ...styles.statusLight, backgroundColor: zelloStatus === 'active' ? 'green' : 'red' }}></div>
                    <p style={styles.statusText}>Zello Service: {zelloStatus}</p>
                </div>

                <div style={styles.section}>
                    <h3 style={styles.subheading}>Add Email</h3>
                    <input 
                        type="email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        placeholder="Enter email" 
                        style={styles.input}
                    />
                    <button onClick={handleAddEmail} style={styles.button}>Add Email</button>
                </div>

                <div style={styles.section}>
                    <h3 style={styles.subheading}>Authorized Emails</h3>
                    <div style={styles.scrollableBox}>
                        <ul style={styles.emailList}>
                            {authorizedEmails.map((email, index) => (
                                <li key={index} style={styles.emailItem}>{email}</li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div style={styles.section}>
                    <h3 style={styles.subheading}>Upload CSV</h3>
                    <input 
                        type="file" 
                        accept=".csv" 
                        onChange={(e) => setCsvFile(e.target.files[0])} 
                        style={styles.inputFile}
                    />
                    <button onClick={handleUploadCSV} style={styles.button}>Upload CSV</button>
                </div>

                <div style={styles.section}>
                    <h3 style={styles.subheading}>Merlin Service Logs</h3>
                    <pre style={styles.logBox}>
                        {logsMerlin}
                    </pre>
                </div>

                <div style={styles.section}>
                    <h3 style={styles.subheading}>Zello Service Logs</h3>
                    <pre style={styles.logBox}>
                        {logsZello}
                    </pre>
                </div>

                <div style={styles.section}>
                    <h3 style={styles.subheading}>Audit Trail (Last 50 Entries)</h3>
                    <pre style={styles.logBox}>
                        {auditTrail.map((entry, index) => (
                            <div key={index} style={styles.auditEntry}>
                                {entry.timestamp} - {entry.user_email} - {entry.action} - {entry.additional_info}
                            </div>
                        ))}
                    </pre>
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundImage: 'url(/images/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '20px'
    },
    card: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        padding: '30px',
        borderRadius: '10px',
        width: '100%',
        maxWidth: '1200px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
        color: '#fff'
    },
    heading: {
        fontSize: '28px',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center'
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '15px'
    },
    statusLight: {
        width: '15px',
        height: '15px',
        borderRadius: '50%',
        marginRight: '10px'
    },
    statusText: {
        fontSize: '18px'
    },
    section: {
        marginBottom: '30px'
    },
    subheading: {
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '10px'
    },
    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontSize: '16px'
    },
    inputFile: {
        marginBottom: '10px'
    },
    button: {
        width: '100%',
        padding: '10px',
        borderRadius: '5px',
        backgroundColor: '#007bff',
        color: '#fff',
        fontSize: '16px',
        cursor: 'pointer',
        border: 'none',
        marginTop: '10px'
    },
    scrollableBox: {
        maxHeight: '200px',
        overflowY: 'scroll',
        backgroundColor: '#333',
        padding: '10px',
        borderRadius: '5px'
    },
    emailList: {
        listStyleType: 'none',
        padding: 0,
        margin: 0
    },
    emailItem: {
        padding: '5px 0',
        borderBottom: '1px solid #444'
    },
    logBox: {
        backgroundColor: '#222',
        color: '#fff',
        padding: '10px',
        borderRadius: '5px',
        maxHeight: '200px',
        overflowY: 'scroll'
    },
    auditEntry: {
        marginBottom: '5px'
    }
};

export default AdminPage;
