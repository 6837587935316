import L from 'leaflet';
import { policeCarIcons, k9Icons, helicopterIcons, esuIcons, boatIcons } from './icons';  // Assuming icons are separated out

const calculateIcon = (heading) => {
    const roundedHeading = Math.round(heading / 90) * 90 % 360;
    //console.log(`Heading: ${heading}, Rounded to: icon${roundedHeading}`);  // Debug: Check heading calculation
    return `icon${roundedHeading}`;
};

export const icons = {
    policeCar: (heading) => {
        //console.log('Fetching Police Car icon for heading:', heading);  // Debug: Identify function call
        return policeCarIcons[calculateIcon(heading)];
    },
    k9: (heading) => {
        //console.log('Fetching K9 icon for heading:', heading);  // Debug
        return k9Icons[calculateIcon(heading)];
    },
    helicopter: (heading) => {
        //console.log('Fetching Helicopter icon for heading:', heading);  // Debug
        return helicopterIcons[calculateIcon(heading)];
    },
    esu: (heading) => {
        //console.log('Fetching ESU icon for heading:', heading);  // Debug
        return esuIcons[calculateIcon(heading)];
    },
    boat: (heading) => {
        //console.log('Fetching Boat icon for heading:', heading);  // Debug
        return boatIcons[calculateIcon(heading)];
    },
};

export const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
};
